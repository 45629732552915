import React from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useParams, useNavigate } from 'react-router-dom';
import { fromBase64 } from './UserInfo';

export default function Call() {
    const {callId,jwt} = useParams();
    const navigate = useNavigate();

    let iframeRef: HTMLDivElement;
    return <JitsiMeeting 
        domain={"videoconf.nibyouservice.net"}
        roomName={callId || ""}
        configOverwrite={{
            disableModeratorIndicator: true,
            defaultLogoUrl: "https://content.kiebel.net/websites/nibyou.de/img/logo-for-circle.png",
            enableClosePage: true,
            enableWelcomePage: false,
            disableDeepLinking: true,
            disableInviteFunctions: true,
            brandingRoomAlias: callId
        }}
        interfaceConfigOverwrite={{
            BRAND_WATERMARK_LINK: "https://nibyou.de",
            DEFAULT_WELCOME_PAGE_LOGO_URL: "https://content.kiebel.net/websites/nibyou.de/img/logo-for-circle.png",
            JITSI_WATERMARK_LINK: "https://nibyou.de",
            SHOW_PROMOTIONAL_CLOSE_PAGE: true,
            /**TOOLBAR_BUTTONS: [
                'microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen',
                'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
                'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
                'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
                'tileview', 'videobackgroundblur', 'help', 'mute-everyone', 'security'
            ],**/
            //HIDE_INVITE_MORE_HEADER: true,
        }}
        onApiReady={(api) => {
            api.on("readyToClose", () => {
                navigate("/call-end");
            });
            api.executeCommand('subject', fromBase64(callId?.split(".")[0] || "") || callId);
        }}
        getIFrameRef = { (iframeReference) => { 
            iframeRef = iframeReference
            iframeReference.style.display = "flex";
            iframeReference.style.height = "100vh";
            console.log(iframeRef);
        } }
        jwt={jwt}
    />
}