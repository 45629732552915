import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export default function EndScreen() {
    return (<div className="Content EndScreen">
        <header>
            <img className="logo" src="/nibyou_big.svg" alt="Nibyou Logo" />
        </header>
        <Container className='p-3 m-1'>
            <Row className='my-auto mx-auto'>
                <h1>Vielen Dank für die Benutzung von Nibyou eConsult!</h1>
            </Row>
        </Container>
        <footer>
            <p>Ein Service der Nibyou GmbH</p>
        </footer>
    </div>);
}