/* tslint:disable */
/* eslint-disable */
/**
 * Nibyou Microservice
 * Microservice Description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'fileType': string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'rawData'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'url'?: string;
    /**
     * 
     * @type {Chat}
     * @memberof Attachment
     */
    'chat': Chat;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface Chat
 */
export interface Chat {
    /**
     * Group chats have an admin
     * @type {string}
     * @memberof Chat
     */
    'admin'?: string;
    /**
     * Group chats have a name, p2p chats should show name of the other user
     * @type {string}
     * @memberof Chat
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Chat
     */
    'members': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    'memberType': ChatMemberTypeEnum;
    /**
     * Used for sorting chats
     * @type {string}
     * @memberof Chat
     */
    'lastMessageAt': string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    'keyId': string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    'updatedAt': string;
}

export const ChatMemberTypeEnum = {
    Practitioner: 'PRACTITIONER',
    Patient: 'PATIENT'
} as const;

export type ChatMemberTypeEnum = typeof ChatMemberTypeEnum[keyof typeof ChatMemberTypeEnum];

/**
 * 
 * @export
 * @interface ChatWithLastMessage
 */
export interface ChatWithLastMessage {
    /**
     * 
     * @type {Chat}
     * @memberof ChatWithLastMessage
     */
    'chat': Chat;
    /**
     * 
     * @type {Message}
     * @memberof ChatWithLastMessage
     */
    'lastMessage': Message;
}
/**
 * 
 * @export
 * @interface ChatsWithLastMessagesDto
 */
export interface ChatsWithLastMessagesDto {
    /**
     * 
     * @type {Array<ChatWithLastMessage>}
     * @memberof ChatsWithLastMessagesDto
     */
    'chats': Array<ChatWithLastMessage>;
}
/**
 * 
 * @export
 * @interface CreateAttachmentDto
 */
export interface CreateAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    'fileType': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    'rawData': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    'chatId': string;
}
/**
 * 
 * @export
 * @interface CreateChatDto
 */
export interface CreateChatDto {
    /**
     * 
     * @type {string}
     * @memberof CreateChatDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateChatDto
     */
    'members': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateChatDto
     */
    'admin': string;
}
/**
 * 
 * @export
 * @interface CreateMessageDto
 */
export interface CreateMessageDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMessageDto
     */
    'chat': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMessageDto
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMessageDto
     */
    'sender': string;
    /**
     * 
     * @type {Array<OmitTypeClass>}
     * @memberof CreateMessageDto
     */
    'attachments': Array<OmitTypeClass>;
}
/**
 * 
 * @export
 * @interface GuestMeetingDto
 */
export interface GuestMeetingDto {
    /**
     * 
     * @type {string}
     * @memberof GuestMeetingDto
     */
    'roomName': string;
    /**
     * 
     * @type {string}
     * @memberof GuestMeetingDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuestMeetingDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface JsonResponse
 */
export interface JsonResponse {
    /**
     * Data from the API response, can be of any type
     * @type {object}
     * @memberof JsonResponse
     */
    'data'?: object;
    /**
     * Message from the API, likely a status
     * @type {string}
     * @memberof JsonResponse
     */
    'message'?: string;
    /**
     * Error, if thrown
     * @type {object}
     * @memberof JsonResponse
     */
    'error'?: object;
    /**
     * Timestamp of the start of the response
     * @type {string}
     * @memberof JsonResponse
     */
    'ts': string;
}
/**
 * 
 * @export
 * @interface JwtDto
 */
export interface JwtDto {
    /**
     * 
     * @type {string}
     * @memberof JwtDto
     */
    'jwt': string;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {Chat}
     * @memberof Message
     */
    'chat': Chat;
    /**
     * Encrypted message contents
     * @type {string}
     * @memberof Message
     */
    'message': string;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Message
     */
    'attachments'?: Array<Attachment>;
    /**
     * Message sender
     * @type {string}
     * @memberof Message
     */
    'sender': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'status': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Message
     */
    'readBy': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ModMeetingDto
 */
export interface ModMeetingDto {
    /**
     * 
     * @type {string}
     * @memberof ModMeetingDto
     */
    'roomName': string;
}
/**
 * 
 * @export
 * @interface OmitTypeClass
 */
export interface OmitTypeClass {
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'fileType': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'rawData': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface S3UrlResponse
 */
export interface S3UrlResponse {
    /**
     * 
     * @type {string}
     * @memberof S3UrlResponse
     */
    'upload': string;
    /**
     * 
     * @type {string}
     * @memberof S3UrlResponse
     */
    'download': string;
}
/**
 * 
 * @export
 * @interface UpdateAttachmentDto
 */
export interface UpdateAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAttachmentDto
     */
    'fileType'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttachmentDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttachmentDto
     */
    'rawData'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttachmentDto
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttachmentDto
     */
    'chatId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateChatDto
 */
export interface UpdateChatDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateChatDto
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateChatDto
     */
    'members'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateChatDto
     */
    'admin'?: string;
}
/**
 * 
 * @export
 * @interface UpdateMessageDto
 */
export interface UpdateMessageDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMessageDto
     */
    'chat'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMessageDto
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMessageDto
     */
    'sender'?: string;
    /**
     * 
     * @type {Array<OmitTypeClass>}
     * @memberof UpdateMessageDto
     */
    'attachments'?: Array<OmitTypeClass>;
}

/**
 * AttachmentApi - axios parameter creator
 * @export
 */
export const AttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new attachment
         * @param {CreateAttachmentDto} createAttachmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerCreate: async (createAttachmentDto: CreateAttachmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAttachmentDto' is not null or undefined
            assertParamExists('attachmentControllerCreate', 'createAttachmentDto', createAttachmentDto)
            const localVarPath = `/attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAttachmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an attachment
         * @param {string} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerFindAll: async (filter: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('attachmentControllerFindAll', 'filter', filter)
            const localVarPath = `/attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all attachments for a message
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerFindForMessage: async (messageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('attachmentControllerFindForMessage', 'messageId', messageId)
            const localVarPath = `/attachment/message/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an attachment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('attachmentControllerFindOne', 'id', id)
            const localVarPath = `/attachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a presigned S3 URL
         * @param {string} ext 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerGetPresignedS3Url: async (ext: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ext' is not null or undefined
            assertParamExists('attachmentControllerGetPresignedS3Url', 'ext', ext)
            const localVarPath = `/attachment/presigned-s3-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ext !== undefined) {
                localVarQueryParameter['ext'] = ext;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an attachment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('attachmentControllerRemove', 'id', id)
            const localVarPath = `/attachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an attachment
         * @param {string} id 
         * @param {UpdateAttachmentDto} updateAttachmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerUpdate: async (id: string, updateAttachmentDto: UpdateAttachmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('attachmentControllerUpdate', 'id', id)
            // verify required parameter 'updateAttachmentDto' is not null or undefined
            assertParamExists('attachmentControllerUpdate', 'updateAttachmentDto', updateAttachmentDto)
            const localVarPath = `/attachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAttachmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentApi - functional programming interface
 * @export
 */
export const AttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new attachment
         * @param {CreateAttachmentDto} createAttachmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentControllerCreate(createAttachmentDto: CreateAttachmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentControllerCreate(createAttachmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an attachment
         * @param {string} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentControllerFindAll(filter: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Attachment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentControllerFindAll(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all attachments for a message
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentControllerFindForMessage(messageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Attachment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentControllerFindForMessage(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an attachment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a presigned S3 URL
         * @param {string} ext 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentControllerGetPresignedS3Url(ext: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3UrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentControllerGetPresignedS3Url(ext, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an attachment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an attachment
         * @param {string} id 
         * @param {UpdateAttachmentDto} updateAttachmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentControllerUpdate(id: string, updateAttachmentDto: UpdateAttachmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentControllerUpdate(id, updateAttachmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttachmentApi - factory interface
 * @export
 */
export const AttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttachmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new attachment
         * @param {CreateAttachmentDto} createAttachmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerCreate(createAttachmentDto: CreateAttachmentDto, options?: any): AxiosPromise<Attachment> {
            return localVarFp.attachmentControllerCreate(createAttachmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an attachment
         * @param {string} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerFindAll(filter: string, options?: any): AxiosPromise<Array<Attachment>> {
            return localVarFp.attachmentControllerFindAll(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all attachments for a message
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerFindForMessage(messageId: string, options?: any): AxiosPromise<Array<Attachment>> {
            return localVarFp.attachmentControllerFindForMessage(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an attachment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerFindOne(id: string, options?: any): AxiosPromise<Attachment> {
            return localVarFp.attachmentControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a presigned S3 URL
         * @param {string} ext 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerGetPresignedS3Url(ext: string, options?: any): AxiosPromise<S3UrlResponse> {
            return localVarFp.attachmentControllerGetPresignedS3Url(ext, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an attachment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.attachmentControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an attachment
         * @param {string} id 
         * @param {UpdateAttachmentDto} updateAttachmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerUpdate(id: string, updateAttachmentDto: UpdateAttachmentDto, options?: any): AxiosPromise<Attachment> {
            return localVarFp.attachmentControllerUpdate(id, updateAttachmentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttachmentApi - object-oriented interface
 * @export
 * @class AttachmentApi
 * @extends {BaseAPI}
 */
export class AttachmentApi extends BaseAPI {
    /**
     * 
     * @summary Create a new attachment
     * @param {CreateAttachmentDto} createAttachmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentControllerCreate(createAttachmentDto: CreateAttachmentDto, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentControllerCreate(createAttachmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an attachment
     * @param {string} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentControllerFindAll(filter: string, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentControllerFindAll(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all attachments for a message
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentControllerFindForMessage(messageId: string, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentControllerFindForMessage(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an attachment
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a presigned S3 URL
     * @param {string} ext 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentControllerGetPresignedS3Url(ext: string, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentControllerGetPresignedS3Url(ext, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an attachment
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentControllerRemove(id: string, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an attachment
     * @param {string} id 
     * @param {UpdateAttachmentDto} updateAttachmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentControllerUpdate(id: string, updateAttachmentDto: UpdateAttachmentDto, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentControllerUpdate(id, updateAttachmentDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChatApi - axios parameter creator
 * @export
 */
export const ChatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new chat
         * @param {CreateChatDto} createChatDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChat: async (createChatDto: CreateChatDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createChatDto' is not null or undefined
            assertParamExists('createChat', 'createChatDto', createChatDto)
            const localVarPath = `/chat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChatDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a chat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChat: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteChat', 'id', id)
            const localVarPath = `/chat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a chat by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getChatById', 'id', id)
            const localVarPath = `/chat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all chats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all chats for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatsForCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove the current user from a chat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCurrentUserFromChat: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeCurrentUserFromChat', 'id', id)
            const localVarPath = `/chat/{id}/me`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a chat
         * @param {string} id 
         * @param {UpdateChatDto} updateChatDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChat: async (id: string, updateChatDto: UpdateChatDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateChat', 'id', id)
            // verify required parameter 'updateChatDto' is not null or undefined
            assertParamExists('updateChat', 'updateChatDto', updateChatDto)
            const localVarPath = `/chat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChatDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatApi - functional programming interface
 * @export
 */
export const ChatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new chat
         * @param {CreateChatDto} createChatDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChat(createChatDto: CreateChatDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Chat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChat(createChatDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a chat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChat(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChat(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a chat by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Chat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all chats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Chat>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all chats for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatsForCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatsWithLastMessagesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatsForCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove the current user from a chat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCurrentUserFromChat(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCurrentUserFromChat(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a chat
         * @param {string} id 
         * @param {UpdateChatDto} updateChatDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChat(id: string, updateChatDto: UpdateChatDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Chat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChat(id, updateChatDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatApi - factory interface
 * @export
 */
export const ChatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new chat
         * @param {CreateChatDto} createChatDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChat(createChatDto: CreateChatDto, options?: any): AxiosPromise<Chat> {
            return localVarFp.createChat(createChatDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a chat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChat(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteChat(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a chat by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatById(id: string, options?: any): AxiosPromise<Chat> {
            return localVarFp.getChatById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all chats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChats(options?: any): AxiosPromise<Array<Chat>> {
            return localVarFp.getChats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all chats for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatsForCurrentUser(options?: any): AxiosPromise<ChatsWithLastMessagesDto> {
            return localVarFp.getChatsForCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove the current user from a chat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCurrentUserFromChat(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeCurrentUserFromChat(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a chat
         * @param {string} id 
         * @param {UpdateChatDto} updateChatDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChat(id: string, updateChatDto: UpdateChatDto, options?: any): AxiosPromise<Chat> {
            return localVarFp.updateChat(id, updateChatDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatApi - object-oriented interface
 * @export
 * @class ChatApi
 * @extends {BaseAPI}
 */
export class ChatApi extends BaseAPI {
    /**
     * 
     * @summary Create a new chat
     * @param {CreateChatDto} createChatDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public createChat(createChatDto: CreateChatDto, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).createChat(createChatDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a chat
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public deleteChat(id: string, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).deleteChat(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a chat by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getChatById(id: string, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).getChatById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all chats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getChats(options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).getChats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all chats for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getChatsForCurrentUser(options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).getChatsForCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove the current user from a chat
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public removeCurrentUserFromChat(id: string, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).removeCurrentUserFromChat(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a chat
     * @param {string} id 
     * @param {UpdateChatDto} updateChatDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public updateChat(id: string, updateChatDto: UpdateChatDto, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).updateChat(id, updateChatDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealth(options?: any): AxiosPromise<JsonResponse> {
            return localVarFp.appControllerGetHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHealth(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerGetHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EconsultApi - axios parameter creator
 * @export
 */
export const EconsultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GuestMeetingDto} guestMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        econsultControllerGetGuestJwt: async (guestMeetingDto: GuestMeetingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guestMeetingDto' is not null or undefined
            assertParamExists('econsultControllerGetGuestJwt', 'guestMeetingDto', guestMeetingDto)
            const localVarPath = `/econsult/guest-jwt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(guestMeetingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModMeetingDto} modMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        econsultControllerGetModJwt: async (modMeetingDto: ModMeetingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modMeetingDto' is not null or undefined
            assertParamExists('econsultControllerGetModJwt', 'modMeetingDto', modMeetingDto)
            const localVarPath = `/econsult/mod-jwt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modMeetingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EconsultApi - functional programming interface
 * @export
 */
export const EconsultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EconsultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GuestMeetingDto} guestMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async econsultControllerGetGuestJwt(guestMeetingDto: GuestMeetingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.econsultControllerGetGuestJwt(guestMeetingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModMeetingDto} modMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async econsultControllerGetModJwt(modMeetingDto: ModMeetingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.econsultControllerGetModJwt(modMeetingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EconsultApi - factory interface
 * @export
 */
export const EconsultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EconsultApiFp(configuration)
    return {
        /**
         * 
         * @param {GuestMeetingDto} guestMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        econsultControllerGetGuestJwt(guestMeetingDto: GuestMeetingDto, options?: any): AxiosPromise<JwtDto> {
            return localVarFp.econsultControllerGetGuestJwt(guestMeetingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModMeetingDto} modMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        econsultControllerGetModJwt(modMeetingDto: ModMeetingDto, options?: any): AxiosPromise<JwtDto> {
            return localVarFp.econsultControllerGetModJwt(modMeetingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EconsultApi - object-oriented interface
 * @export
 * @class EconsultApi
 * @extends {BaseAPI}
 */
export class EconsultApi extends BaseAPI {
    /**
     * 
     * @param {GuestMeetingDto} guestMeetingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EconsultApi
     */
    public econsultControllerGetGuestJwt(guestMeetingDto: GuestMeetingDto, options?: AxiosRequestConfig) {
        return EconsultApiFp(this.configuration).econsultControllerGetGuestJwt(guestMeetingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModMeetingDto} modMeetingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EconsultApi
     */
    public econsultControllerGetModJwt(modMeetingDto: ModMeetingDto, options?: AxiosRequestConfig) {
        return EconsultApiFp(this.configuration).econsultControllerGetModJwt(modMeetingDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new message
         * @param {CreateMessageDto} createMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage: async (createMessageDto: CreateMessageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMessageDto' is not null or undefined
            assertParamExists('createMessage', 'createMessageDto', createMessageDto)
            const localVarPath = `/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a message
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMessage', 'id', id)
            const localVarPath = `/message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all messages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMessages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all messages for a given chat
         * @param {string} chatId 
         * @param {string} [start] MongoDB ObjectId of the last item of the previous page
         * @param {number} [skip] Number of items to skip
         * @param {number} [limit] Number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMessagesForChat: async (chatId: string, start?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('getAllMessagesForChat', 'chatId', chatId)
            const localVarPath = `/message/chat/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a message by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMessageById', 'id', id)
            const localVarPath = `/message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a message
         * @param {string} id 
         * @param {UpdateMessageDto} updateMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessage: async (id: string, updateMessageDto: UpdateMessageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMessage', 'id', id)
            // verify required parameter 'updateMessageDto' is not null or undefined
            assertParamExists('updateMessage', 'updateMessageDto', updateMessageDto)
            const localVarPath = `/message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new message
         * @param {CreateMessageDto} createMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMessage(createMessageDto: CreateMessageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMessage(createMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a message
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMessage(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMessage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all messages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMessages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Message>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all messages for a given chat
         * @param {string} chatId 
         * @param {string} [start] MongoDB ObjectId of the last item of the previous page
         * @param {number} [skip] Number of items to skip
         * @param {number} [limit] Number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMessagesForChat(chatId: string, start?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Message>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMessagesForChat(chatId, start, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a message by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a message
         * @param {string} id 
         * @param {UpdateMessageDto} updateMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMessage(id: string, updateMessageDto: UpdateMessageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMessage(id, updateMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new message
         * @param {CreateMessageDto} createMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage(createMessageDto: CreateMessageDto, options?: any): AxiosPromise<Message> {
            return localVarFp.createMessage(createMessageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a message
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMessage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all messages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMessages(options?: any): AxiosPromise<Array<Message>> {
            return localVarFp.getAllMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all messages for a given chat
         * @param {string} chatId 
         * @param {string} [start] MongoDB ObjectId of the last item of the previous page
         * @param {number} [skip] Number of items to skip
         * @param {number} [limit] Number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMessagesForChat(chatId: string, start?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<Message>> {
            return localVarFp.getAllMessagesForChat(chatId, start, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a message by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageById(id: string, options?: any): AxiosPromise<Message> {
            return localVarFp.getMessageById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a message
         * @param {string} id 
         * @param {UpdateMessageDto} updateMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessage(id: string, updateMessageDto: UpdateMessageDto, options?: any): AxiosPromise<Message> {
            return localVarFp.updateMessage(id, updateMessageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * 
     * @summary Create a new message
     * @param {CreateMessageDto} createMessageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public createMessage(createMessageDto: CreateMessageDto, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).createMessage(createMessageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a message
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public deleteMessage(id: string, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).deleteMessage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all messages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getAllMessages(options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).getAllMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all messages for a given chat
     * @param {string} chatId 
     * @param {string} [start] MongoDB ObjectId of the last item of the previous page
     * @param {number} [skip] Number of items to skip
     * @param {number} [limit] Number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getAllMessagesForChat(chatId: string, start?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).getAllMessagesForChat(chatId, start, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a message by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getMessageById(id: string, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).getMessageById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a message
     * @param {string} id 
     * @param {UpdateMessageDto} updateMessageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public updateMessage(id: string, updateMessageDto: UpdateMessageDto, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).updateMessage(id, updateMessageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


