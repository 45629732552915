import React from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  useParams,
} from "react-router-dom";
import Call from './pages/Call';
import EndScreen from './pages/EndScreen';
import UserInfo from './pages/UserInfo';

function App() {
  const router = createBrowserRouter([
    {
      path: "/call/:callId/:jwt",
      element: <Call />,
      errorElement: <NotFound />
    },
    {
      path: "/call-end",
      element: <EndScreen />
    },
    {
      path: "/join/:callId",
      element: <UserInfo />,
      errorElement: <NotFound />
    }, 
    {
      path: "/:callId",
      element: <IndexRoute />
    },
    {
      path: "/",
      element: <NotFound />,
      errorElement: <NotFound />
    }
  ]);

  return <React.StrictMode>
  <RouterProvider router={router} />
</React.StrictMode>
}

export default App;

function IndexRoute() {
  const {callId} = useParams();
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate(`/join/${callId}`);
  }, [callId, navigate]);
  return <></>;
}

function NotFound() {
  return <h1>Not found</h1>
}