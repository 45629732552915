import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { EconsultApi } from '../generated';

export default function UserInfo() {
    const {callId} = useParams();
    const navigate = useNavigate();
    const [email, setEmail] = React.useState("");
    const [userName, setUserName] = React.useState("");

    const submitAndRedirect = (e: any) => {
        e.preventDefault();
        (async () => {
            const roomName = callId ? callId.toLocaleLowerCase().replaceAll("=", "") : "";
            const { data } = await EconsultApi.econsultControllerGetGuestJwt({
                roomName: roomName,
                email: email,
                username: userName,
            });
            navigate(`/call/${callId}/${data.jwt}`);
        })();
    }

    return (<div className="Content UserInfo">
        <header>
            <img className="logo" src="/nibyou_big.svg" alt="Nibyou Logo" />
        </header>
        <main>
            <h1>{ `Videokonferenz ${fromBase64(callId?.split(".")[0] || "") || callId} beitreten:` }</h1>
            <Row className="my-auto mx-auto">
                <Col xs lg="8">
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email-Adresse</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email eingeben..."
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <small>Die Email-Adresse wird nicht gespeichert.</small>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicUsername">
                            <Form.Label>Anzeigename</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Anzeigename eingeben..."
                                value={userName}
                                onChange={e => setUserName(e.target.value)}
                            />
                            <small>Der Anzeigename ist für andere Teilnehmer sichtbar.</small>
                        </Form.Group>
                        <button type="submit" onClick={submitAndRedirect}>Videokonferenz beitreten</button>
                    </Form>
                </Col>
            </Row>
        </main>
        <footer>
            <p>Ein Service der Nibyou GmbH</p>
        </footer>
    </div>);
}

export function toBase64(str: string): string {
    return window.btoa((encodeURIComponent(str)));
}

export function fromBase64(str: string): string {
    return decodeURIComponent((window.atob(str)));
}
